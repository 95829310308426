"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.registerForm = exports.loginV3 = void 0;

const registerForm = () => {
  $('[data-toggle="register"]').on('click', function (e) {
    e.stopPropagation();
    $(this).parents('#login_content').toggleClass('open');
  });
};

exports.registerForm = registerForm;

const loginV3 = () => {
  $('#login-wrapper .btn-fab').on('click', function (e) {
    e.stopPropagation();
    $(this).parents('.card').toggleClass('active');
  });
};

exports.loginV3 = loginV3;