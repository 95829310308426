"use strict";

var _appState = require("./modules/appState");

var _drawers = require("./modules/drawers");

var _animations = require("./modules/animations");

var _search = require("./modules/search");

var _cards = require("./modules/cards");

var _sidebars = require("./modules/sidebars");

var _listItems = require("./modules/listItems");

var _photoSwipe = require("./modules/photoSwipe");

var _mail = require("./modules/mail");

var _chips = require("./modules/chips");

var _stepper = require("./modules/stepper");

var _panelExpand = require("./modules/panelExpand");

var _auth = require("./modules/auth");

var _alerts = require("./modules/alerts");

var _initPlugins = require("./modules/initPlugins");

var _transitions = require("./modules/transitions");

var _matchElementHeight = require("./modules/matchElementHeight");

var _demo = require("./modules/demo");

var _contextMenu = require("./modules/contextMenu");

var _dataTables = require("./modules/dataTables");

var _gmap = require("./modules/gmap");

var _sidebarChat = require("./modules/sidebarChat");

var _task = require("./modules/task");

var _fileManager = require("./modules/fileManager");

/*
*
* MaterialWrap
* Version: 1.0
* Author: http://authenticgoods.co
*
*/
//import the JavaScript modules to run
// import {currentDateTimeSidebar, nextThreeDays, todaysDate, timlineInput} from './modules/dateTime';
//App View Modules
var MaterialWrap = window.MaterialWrap || (window.MaterialWrap = {});

(function (window) {
  if (window.Package) {
    Materialize = {};
  } else {
    window.Materialize = {};
  }
})(window); // Unique ID


Materialize.guid = function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  return function () {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  };
}();

$(function () {
  $.material.init();
  (0, _initPlugins.selectDropdowns)();
  (0, _initPlugins.scrollBar)();
  (0, _initPlugins.keepDropdownOpen)();
  (0, _initPlugins.slickCarousel)();
  (0, _initPlugins.videoPlayer)();
  (0, _initPlugins.initToolbarjs)();
  (0, _initPlugins.materialAvatar)();
  (0, _sidebars.initSubMenu)();
  (0, _appState.toggleState)();
  (0, _appState.toggleExpand)();
  (0, _search.navBarSearch)();
  (0, _cards.cardRefresh)();
  (0, _cards.cardToggleHighlighter)();
  (0, _sidebars.switchMenuState)();
  (0, _sidebars.openProfileMenu)();
  (0, _sidebars.openThemeSettingPanel)();
  (0, _dataTables.mwDataTables)();
  (0, _cards.cardStacks)();
  (0, _cards.cardOffCanvas)();
  (0, _drawers.toggleDrawer)(); // --- dateTime module
  // currentDateTimeSidebar();
  // todaysDate();
  // timlineInput();
  // nextThreeDays();
  // -------------------

  (0, _cards.cardCollapse)();
  (0, _sidebars.closeOpenState)();
  (0, _initPlugins.initTooltips)();
  (0, _initPlugins.initPopovers)();
  (0, _initPlugins.otherScrollbarOptions)();
  (0, _transitions.fullscreenTransition)();
  (0, _demo.css3AnimationDemos)();
  (0, _demo.iconModal)();
  (0, _auth.loginV3)();
  (0, _auth.registerForm)();
  (0, _alerts.sweetAlerts)();
  (0, _alerts.alertifyjs)();
  (0, _cards.cardSearch)();
  (0, _panelExpand.expansionPanel)();
  (0, _stepper.simpleStepper)();
  (0, _chips.chips)();
  (0, _sidebarChat.sidebarChatCompose)();
  (0, _chips.initChips)();
  (0, _listItems.dismissListItem)();
  (0, _initPlugins.initSliders)();
  (0, _cards.cardReveal)();
  (0, _cards.cardTask)();
  (0, _contextMenu.contextMenu)();
  (0, _initPlugins.materialDatePicker)();
  (0, _initPlugins.pikaday)();
  (0, _initPlugins.triggerFormValidation)();
  (0, _animations.fabMenu)();
  (0, _animations.toggleCard)();
  (0, _animations.toggleSearch)();
  (0, _photoSwipe.initPhotoSwipeFromDOM)();
  (0, _demo.cardCarousel)();
  (0, _demo.cardDemoMorrisChart)();
  (0, _demo.loadThemes)();
  (0, _demo.swapLayoutMode)(); //app views

  (0, _fileManager.selectedItem)();
  (0, _dataTables.checkAll)();
  (0, _dataTables.deleteItem)();
  (0, _dataTables.pagination)();
  (0, _mail.mailList)();
  (0, _mail.mailCompose)();
  (0, _mail.mailSelected)();
  (0, _task.loadTaskId)();
  (0, _task.getTaskCardInfo)();
  (0, _task.addNewTask)();
  (0, _task.addNewTaskList)();
  (0, _task.deleteTask)();
  (0, _task.editTask)();
  (0, _task.filterTaskMembers)();
  (0, _task.dragDropTask)(); //Pages

  (0, _gmap.loadGmaps)();

  if (Modernizr.mq("screen and (min-width:768px)")) {
    (0, _matchElementHeight.matchElementHeight)('.match-height .card');
  }
});
$(document).on('resize', function () {
  _.debounce(_sidebars.switchMenuState, 300, false)();
});