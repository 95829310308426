"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedItem = void 0;

const selectedItem = () => {
  let card = {
    $item: $('.file-manager .card-item'),
    dataItemSize: 'item-size',
    dataItemImage: 'item-image',
    dataItemTitle: 'item-title',
    dataItemDesc: 'item-desc',
    dataItemTier: 'item-tier',
    dataItemMimeType: 'item-mime-type',
    dataItemUploaded: 'item-uploaded',
    dataItemTags: 'item-tags'
  };
  let itemPanel = {
    $itemImage: $('.item-panel #current-img'),
    $itemHeaderTitle: $('.item-panel #item-title .title'),
    $itemDesc_titleInfo: $('.item-panel #item-desc .title'),
    $itemTier_titleInfo: $('.item-panel #item-details #tier .info'),
    $itemMimeType_titleInfo: $('.item-panel #item-details #mime-type .info'),
    $itemUploaded_titleInfo: $('.item-panel #item-details #uploaded .info'),
    $itemTags_titleInfo: $('.item-panel #item-details #tags .badge'),
    $itemSize_titleInfo: $('.item-panel #item-details #size .info')
  };
  card.$item.on('click', function (event) {
    event.stopPropagation();
    card.$item.removeClass('selected');
    $(this).addClass('selected'); //Selected Data Attributes

    itemPanel.$itemDesc_titleInfo.text($(this).data(card.dataItemDesc));
    itemPanel.$itemTier_titleInfo.text($(this).data(card.dataItemTier));
    let mimeType = $(this).data(card.dataItemMimeType);
    itemPanel.$itemMimeType_titleInfo.text(mimeType);
    itemPanel.$itemUploaded_titleInfo.text($(this).data(card.dataItemUploaded));
    let tags = $(this).data(card.dataItemTags);
    itemPanel.$itemTags_titleInfo.text(tags);
    itemPanel.$itemSize_titleInfo.text($(this).data(card.dataItemSize));
    let currImage = $(this).data(card.dataItemImage);
    itemPanel.$itemHeaderTitle.text($(this).data(card.dataItemTitle));
    itemPanel.$itemImage.empty();
    itemPanel.$itemImage.append(`<img src="${currImage}" />`);

    if (mimeType == "Folder") {
      $('.item-panel #item-details #size').addClass("hidden");
    } else {
      $('.item-panel #item-details #size').removeClass("hidden");
    }

    if (tags == "") {
      $('.item-panel #item-details #tags').addClass("hidden");
    } else {
      $('.item-panel #item-details #tags').removeClass("hidden");
    }
  });
};

exports.selectedItem = selectedItem;